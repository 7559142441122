<template>
    <div>
        <div class="data-report-label-box" @click="onDisplayTree">
            <div class="label-box" v-if="selected.length > 0">
                <div class="label-item-box">
                    <p class="value">{{ selected[0].title }}</p>
                </div>
                <div class="label-item-box" v-if="selected.length > 1">
                    <p class="value">+{{ selected.length - 1 }}</p>
                </div>
            </div>
            <div class="ph" v-else>请选择指标</div>
        </div>

        <CompModal class="data-report-modal" ref="comp_modal" title="选择指标" height="auto">
            <template #head-operate>
                <div class="modal-operate-btn" @click="onSubmitTree">保存</div>
            </template>
            <div class="tree-box">
                <Tree ref="tree" :data="cascader" show-checkbox></Tree>
            </div>
        </CompModal>
    </div>
</template>

<script>
import Request from "../../jointly/utils/request"
import CompModal from "../../residentdatabase/components/CompModal.vue"

export default {
    components: {
        CompModal,
    },

    data() {
        return {
            cascader: [],
            selected: [],
            tile: [],
        }
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },

    model: {
        prop: "value",
        event: "change",
    },

    watch: {
        value: {
            handler(v) {
                if (!v) {
                    this.selected = []
                } else if (v !== this.val) {
                    this.selected = this.tile?.filter(f => v.includes(f.value)) || []
                }
            },
            immediate: true,
        },

        selected(v) {
            this.$emit("change", (this.val = v.map(v => v.value)))
        },

        tile(val) {
            this.selected = this.value?.map(v => val?.find(f => f.value === v)).filter(f => !!f) || []
        },
    },

    created() {
        this.getCascaderTree()
    },

    methods: {
        onDisplayTree() {
            const process = params => {
                params?.forEach(item => {
                    item.checked = this.selected.findIndex(v => v?.value === item.value) > -1

                    if (item.children?.length) {
                        process(item.children)
                    }
                })

                return params
            }

            // 处理已选
            this.cascader = process(this.cascader)

            this.$refs.comp_modal.display()
        },

        getCascaderTree() {
            Request.get("/gateway/api/ck/OrgIndex/selectTree").then(res => {
                var tile = []

                function process(params, level = 0) {
                    params = params.map(item => {
                        return {
                            level: level,
                            value: item.indexCode,
                            title: item.indexName,
                            expand: true,
                            checked: false,
                            children: item.subList ? process(item.subList, level + 1) : [],
                        }
                    })

                    tile.push(...params)

                    return params
                }

                this.cascader = process(res)
                this.tile = tile
            })
        },

        onSubmitTree() {
            const s = this.$refs.tree.getCheckedNodes()
            // 只取第二级
            this.selected = s.filter(v => v.level === 1)
            // 关闭弹窗
            this.$refs.comp_modal.close()
        },
    },
}
</script>

<style lang="less">
.data-report-label-box {
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    height: 32px;
    padding: 0 50px 0 6px;

    &:hover {
        border-color: #b3b3b3;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .label-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }

    .label-item-box {
        margin-right: 5px;
        padding: 4px 10px 4px 10px;
        display: flex;
        align-items: center;
        background: #2faaf7;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;
        max-width: 100%;
        word-break: break-word;

        .value {
            font-size: 14px;
            color: #fff;
            line-height: 1;
        }
    }

    .ph {
        color: #b3b3b3;
    }
}

.data-report-modal {
    .modal-operate-btn {
        cursor: pointer;
        padding: 3px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2faaf7;
        color: #fff;
        border-radius: 4px;
    }

    .tree-box {
        padding: 10px 20px;
    }
}
</style>
